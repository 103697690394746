import { object } from "yup"
import CellRoutes, { CELL_ROUTES } from "./CellRoutes"

const MAIN_ROUTES_CONST = {
    dashboard: "dashboard",
    welcome: "welcome",
    marketplace: "marketplace",
    investment_calculator: "investment-calculator",
    forgot: "forgot",
    verify_otp: "otp/verify",
    reset_password: "reset-password",
    success_reset_password: "success-reset-password",
    faq: "faq",
    about_us: "about",
    contact_us: "contact",
    crowd_funding: "crowd-funding",
    green_energy: "green-energy",
    cell: "cell",
    solarkit: "solar-kits",
    product_details: "product-details",
    wallet: "wallet",
    profile: "profile",
    new_enquiry_request: "new-enquiry-request",
    existing_enquiry_rquest: "existing-enquiry-request",
    p2pEnergyTrading: "p2p-energy-trading",
    epcContractor:"epcContractor",
    epcContractorDetails:"epcContractor-details",
    manufacturers:"manufacturers",
    manufacturersDetails:"manufacturers-details"
}


const RESTRICT_LAYOUT_PATH = [
    MAIN_ROUTES_CONST.marketplace,
    // MAIN_ROUTES_CONST.investment_calculator,
    MAIN_ROUTES_CONST.product_details,
    // MAIN_ROUTES_CONST.wallet,
    // MAIN_ROUTES_CONST.profile,
    MAIN_ROUTES_CONST.new_enquiry_request,
    MAIN_ROUTES_CONST.existing_enquiry_rquest,
    // MAIN_ROUTES_CONST.epcContractor
]


const AUTH_ROUTES_CONST = {

}

const APP_ROUTES_CONST = {
    dashboard: "dashboard"
}

export { MAIN_ROUTES_CONST, APP_ROUTES_CONST, RESTRICT_LAYOUT_PATH }