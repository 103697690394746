import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { cn } from 'src/helpers'
import {
    Button,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    NavigationMenuLink
} from "src/shadcn/components/ui"

type NavMenuItem = {
    title: string,
    link?: string,
    subLinks?: NavMenuItem[]
}

export type INavMenu = NavMenuItem[]




const NavMenuComponent = ({ navs }: { navs: INavMenu }) => {

    const navigate = useNavigate()
    const { pathname } = useLocation()

    return (
        <div className='flex space-x-2'>
            {
                navs.map(({ link, title, subLinks }) => {

                    if (link) {
                        console.log({ link });
                        return <Button key={`nav-menu-tab-${title}-${link}`} onClick={() => navigate("/" + link)} variant={'ghost'} className={cn({ " text-primary ": pathname == "/" + link })} >{title}</Button>
                    }
                    else if (subLinks) {
                        return <DropdownMenu key={`nav-menu-tab-${title}-${link}`}>
                            <DropdownMenuTrigger>
                                <Button key={`nav-menu-tab-${title}-${link}`} variant={'ghost'} className={cn({ " text-primary ": subLinks.some(({ link }) => pathname == "/" + link) })} >{title}</Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                {
                                    subLinks.map(({ title: subTitle, link: subLink }) => {
                                        return <DropdownMenuItem className='p-0' key={`nav-menu-sub-tab-${subTitle}-${subLink}`}>
                                            <Button onClick={() => navigate("/" + subLink)} variant={'ghost'} className={cn({ " text-primary ": pathname == "/" + subLink })} >{subTitle}</Button>
                                        </DropdownMenuItem>
                                    })
                                }
                            </DropdownMenuContent>
                        </DropdownMenu>
                    }
                })
            }
        </div>
    )
}

export default NavMenuComponent