import React from 'react'
import { IMG_QUOTE_IMG } from 'src/helpers'
import QuteForm from './QuteForm'

type Props = {}

const QuoteModule = (props: Props) => {
    return (
        <div className='  grid grid-cols-2 gap-0 h-[75vh] overflow-hidden'>
            <img src={IMG_QUOTE_IMG} className='w-full  '  />
            <div className='bg-gray-100 p-12 space-y-4'>
                <div className='text-primary font-semibold'>Free Quote</div>
                <div className='text-3xl font-bold'>Get A Free Quote</div>
                <div>
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo erat amet
                </div>
                <div>
                    <QuteForm />
                </div>
            </div>
        </div>
    )
}

export default QuoteModule