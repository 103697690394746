import { MoveUpRight } from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom'
import { IMG_FEATURE_INFO_1, IMG_FEATURE_INFO_2, IMG_FEATURE_INFO_3, IMG_FEATURE_INFO_4, IMG_FEATURE_P2P_CROWDFUNDING, IMG_FEATURE_P2P_ENERGY_TRADING, IMG_FEATURE_P2P_MARKETPLACE } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { Button } from 'src/shadcn/components/ui/button'
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from 'src/shadcn/components/ui/carousel'

type Props = {}

const DATA = [
    {
        image: IMG_FEATURE_P2P_ENERGY_TRADING,
        header: "P2P energy trading",
        sub_header: "Distributed Energy Resources (DER)",
        content: "• Monitoring  • Trading  • Control",
        navigation: "https://p2p.orangecurrent.com/"
    },
    {
        image: IMG_FEATURE_P2P_CROWDFUNDING,
        header: "P2P crowdfunding",
        sub_header: "Build, own , operate and transfer to the owners after receipt of full payment in 7 years",
        content: "",
        navigation: "/" + MAIN_ROUTES_CONST.crowd_funding
    },
    {
        image: IMG_FEATURE_P2P_MARKETPLACE,
        header: "Tokenized energy marketplace",
        sub_header: "Let’s know Your Green Energy Need  Our Platform will fulfil it 100%* !!!",
        content: "",
        navigation: "/" + MAIN_ROUTES_CONST.green_energy
    },
    {
        image: IMG_FEATURE_P2P_MARKETPLACE,
        header: "Tokenized energy marketplace",
        sub_header: "Let’s know Your Green Energy Need  Our Platform will fulfil it 100%* !!!",
        content: "",
        navigation: "/" + MAIN_ROUTES_CONST.green_energy
    }
]


const FeaturesSection = (props: Props) => {

    const navigate = useNavigate()

    const handleNavigation = (navigation: any) => {
        navigate(navigation)
    }

    return (
        <section className="  bg-white">
            <div className='space-y-1 my-6'>
                <div className='font-bold text-lg text-center'>Products on Blockchain </div>
                <div className='text-center text-sm '><i>Products on Blockchain Enabled solar and Energy Marketplace</i></div>
            </div>
            <div className=''>
                <Carousel
                    opts={{ align: "start", }}
                    className="w-full"
                >
                    <CarouselContent>
                        {DATA.map(({ image, header, sub_header, content, navigation }, index) => {

                            console.log({ navigation });

                            return (
                                <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                                    {
                                        navigation.startsWith("https") ? <Link to={navigation} target='_blank'>
                                            <div className='relative h-[250px] rounded-2xl overflow-hidden cursor-pointer' style={{ backgroundImage: "url(" + image + ")", backgroundSize: "cover" }}>
                                                <div className='absolute left-0 right-0 bottom-0  p-6 text-white bg-slate-700 border border-t-primary border-t-4 border-l-0 border-r-0 border-b-0 space-y-2'>
                                                    <div className='flex justify-between'><div className='text-lg'>{header}</div> <MoveUpRight className='text-primary' /></div>
                                                    <div className='text-xs font-light w-80'>
                                                        <div ><i>{sub_header}</i> </div>
                                                        <div><i>{content}</i> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link> : <>
                                            <div className='relative h-[250px] rounded-2xl overflow-hidden cursor-pointer' onClick={() => {
                                                handleNavigation(navigation)
                                                window.scrollTo(0, 0);
                                            }} style={{ backgroundImage: "url(" + image + ")", backgroundSize: "cover" }}>
                                                <div className='absolute left-0 right-0 bottom-0  p-6 text-white bg-slate-700 border border-t-primary border-t-4 border-l-0 border-r-0 border-b-0 space-y-2'>
                                                    <div className='flex justify-between'><div className='text-lg'>{header}</div> <MoveUpRight className='text-primary' /></div>
                                                    <div className='text-xs font-light w-80'>
                                                        <div ><i>{sub_header}</i> </div>
                                                        <div><i>{content}</i> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                </CarouselItem>
                            )
                        })}
                    </CarouselContent>
                    <CarouselPrevious />
                    <CarouselNext />
                </Carousel>
            </div>
            <div className='flex justify-center items-center my-[100px] '>
                <div className='px-40 space-y-6'>
                    <div className='text-center'> Let's <b>Collaborate</b> and <b>Innovate! Together</b><br />
                        we can build the energy companies of the future using Energy 4.0 technologies.
                        Here are some of our innovative solutions.</div>
                    <div className='text-center'>Customers can buy and sell energy directly with one another nationwide, in the absence of intermediaries. The energy sharing activities on the energy marketplace are all in compliance with the frameworks of existing legislations.  </div>
                </div>
            </div>
            <div>
                <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-4 my-[100px]'>
                    <div className=''>
                        <div className='flex justify-center  '>
                            <div className=''><img src={IMG_FEATURE_INFO_1} /></div>
                        </div>
                    </div>
                    <div className=''>
                        <div className='flex '>
                            <div><MoveUpRight className='text-primary' /></div>
                            <div className='space-y-4'>
                                <div className='text-xl uppercase font-bold'>
                                    <div className=''>Electricity is a <br /> blockchain-based </div>
                                    <div className='text-primary' >P2P Energy Marketplace </div>
                                </div>
                                <div>The Electricity Marketplace offers a wide variety of features that make it an ideal place to buy and sell renewable energy. </div>
                                <Button onClick={() => {
                                    window.open("https://p2p.orangecurrent.com/", "_blank")
                                    window.scrollTo(0, 0);
                                }} className='rounded-full'>Learn More</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-4 my-[100px] '>
                    <div className=''>
                        <div className='flex justify-end'>
                            <div className='space-y-4'>
                                <div className='text-xl uppercase font-bold'>
                                    <div className='text-primary flex justify-end' >
                                        <MoveUpRight className='text-primary' /> <span> Solar Marketplace</span>
                                    </div>
                                    <div className='text-right'>to Solve your all <br />Project Needs </div>
                                </div>
                                <div className='text-right'>The Electricity Marketplace offers a wide variety of features that make it an ideal place to buy and sell renewable energy. </div>
                                <div className='flex justify-end'>
                                    <Button className='rounded-full' onClick={() => {
                                        navigate("/" + MAIN_ROUTES_CONST.crowd_funding)
                                        window.scrollTo(0, 0);
                                    }} >Learn More</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className='flex justify-center  '>
                            <div className=''><img src={IMG_FEATURE_INFO_2} /></div>
                        </div>
                    </div>

                </div>

                <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-4 my-[100px]'>
                    <div className=''>
                        <div className='flex justify-center  '>
                            <div className=''><img src={IMG_FEATURE_INFO_3} /></div>
                        </div>
                    </div>
                    <div className=''>
                        <div className='flex '>
                            <div><MoveUpRight className='text-primary' /></div>
                            <div className='space-y-4'>
                                <div className='text-xl uppercase font-bold'>
                                    <div className=''>Now, Buy your own <br /> Green Energy through </div>
                                    <div className='text-primary' >Virtual Solar Marketplace</div>
                                </div>
                                <div>Want to buy green Energy, but do not have space or cannot own the Solar plant, out Blockchain technology enabled Virtual Solar Plants (VSP)...</div>
                                <div>
                                    <Button onClick={() => {
                                        navigate("/" + MAIN_ROUTES_CONST.green_energy)
                                        window.scrollTo(0, 0);
                                    }} className='rounded-full'>Learn More</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-4 my-[100px]'>
                    <div className='space-y-4'>
                        <div className='flex'>
                            <div><MoveUpRight className='text-primary' /></div>
                            <div className='font-bold uppercase'>
                                <div className='text-xl'>Methods of</div>
                                <div className='text-xl text-primary'>Payment</div>
                            </div>
                        </div>
                        <div className='pl-6'>
                            <div>You can choose any of the payment methods in your account with Orange Current as your preferred payment method in the Funding section of your account settings.</div>
                            <div>Orange Current  allows you to make payments using the following methods:</div>
                        </div>
                        <div>
                            <div className='grid sm:grid-cols-1 md:grid-cols-3 gap-4'>
                                <div className='flex border-t-0 border-l-0 border-r-2 border-b-0 border-primary justify-center '>
                                    <div><MoveUpRight className='text-primary' /></div>
                                    <div className='text-primary font-bold text-md'>Payment <br /> Gateway</div>
                                </div>
                                <div className='flex border-t-0 border-l-0 border-r-2 border-b-0 border-primary justify-center  '>
                                    <div><MoveUpRight className='text-primary' /></div>
                                    <div className='text-primary font-bold text-md'>OCT <br /> Tokens</div>
                                </div>
                                <div className='flex '>
                                    <div><MoveUpRight className='text-primary' /></div>
                                    <div className='text-primary font-bold text-md'>Dollor's via Fast  <br /> & Easy Deposits</div>
                                </div>
                            </div>
                        </div>
                        <div className='pl-6'> <Button className='rounded-full'>Learn More</Button></div>
                    </div>
                    <div className=''>
                        <div className='flex justify-center  '>
                            <div className=''><img src={IMG_FEATURE_INFO_4} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturesSection