import { FormField, NUMBER_REGEX, PROJECT_LAND_TYPES, QUERY_TYPE_OPTIONS, SERVICE_TYPES } from "src/helpers";
import * as Yup from 'yup';


export interface IQuteForm {
    name: string,
    phoneNumber: string,
    email: string,
    projectType: string,
    description: string,
}

export const QUOTE_FORM_FIELDS: FormField[] = [
    {
        label: 'Name',
        dataKey: 'name',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter your name',
        },
    },
    {
        label: 'Phone Number',
        dataKey: 'phoneNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex:NUMBER_REGEX,
            placeholder: 'Enter your phone number',
            maxLength:10
        },
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: 'email',
            placeholder: 'Enter your email',
        },
    },
   
    {
        label: 'Project Type',
        dataKey: 'projectType',
        componentType: 'options',
        componentProps: {
            options:SERVICE_TYPES
        },
    },
    {
        label: 'Description',
        dataKey: 'description',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Enter your query description',
        },
    },
];


export const QUOTE_INIT: IQuteForm = {
    name: '',
    phoneNumber: '',
    email: '',
    projectType:"",
    description: '',
  };


export const quoteFormSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    projectType: Yup.string().required('Query type is required'),
    description: Yup.string().required('Description is required'),
    
});