import { MoveUpRight } from 'lucide-react'
import React from 'react'
import ListMain from 'src/modules/SolarProducts/ListMain'

type Props = {}

const SolarKIts = (props: Props) => {
  return (
    <div className=' max-w-[90vw] mx-auto'>
      <div className='space-y-2 py-4'>
        <div className='my-8 text-xl uppercase space-x-2 flex '><span><MoveUpRight className='text-primary ' /></span> <span className=' font-semibold' >Solar Products </span></div>
        <ListMain />
      </div>
    </div>
  )
}

export default SolarKIts