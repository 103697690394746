import React from 'react'
import { IMG_EPC_COMPANY_LOGO } from 'src/helpers'
import { Rating } from '@smastrom/react-rating'
import { Send } from 'lucide-react'
import { RiMailSendLine } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import { MAIN_ROUTES_CONST } from 'src/navigations/modules';

type Props = {
    contractorName: string;
    city: string,
    noOfReview: string;
    companyLogo: string;
    contractorEmail: string,
    epc_contractor_id:string
}

const EPCContractorProfileTile = ({ contractorName, city, noOfReview, companyLogo, contractorEmail,epc_contractor_id }: Props) => {

    const navigation = useNavigate()

    return (
        <button onClick={()=>  navigation("/" + MAIN_ROUTES_CONST.epcContractorDetails+"/"+ epc_contractor_id)}>
            <div className='h-[400px] w-100 grid grid-rows-4 gap-0 bg-white rounded-xl overflow-hidden shadow-xl  '>
                <div className='bg-white row-span-3 flex justify-center items-center'>
                    <img src={companyLogo || ""} style={{ width: "100%" }} />
                </div>
                <div className=' p-4 bg-gray-100'>
                    <div className='flex justify-between items-start'>
                        <div>
                            <div className='font-semibold text-left'>{contractorName}</div>
                            <Rating value={Number(noOfReview) || 0} style={{ height: 15, width: "80px" }} readOnly />
                            <div className='text-gray-400 text-xs mt-2'>{city}</div>
                        </div>
                        <Link to={`mailto:${contractorEmail}`} target='_blank' >
                            <div className=' cursor-pointer border-2 hover:bg-primary text-gray-400  p-2 flex justify-center items-center rounded-full hover:text-white'>
                                <RiMailSendLine size={20} className='' />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </button>
    )
}

export default EPCContractorProfileTile