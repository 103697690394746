import React from 'react'
import { Skeleton } from 'src/shadcn/components/ui'

type Props = {}

const EpcContractorLoder = (props: Props) => {
    return (
        <div className='h-[400px] w-100 grid grid-rows-4 gap-0 bg-white rounded-xl overflow-hidden  border-2 '>
            <div className='bg-white row-span-3 flex justify-center items-center'>
                <Skeleton className='w-full h-full' />
            </div>
            <div className=' p-4 bg-gray-50'>
                <div className='flex justify-between items-start'>
                    <div>
                        <Skeleton className='w-[150px] h-2' />
                        <Skeleton className='w-[150px] h-2 my-2' />
                        <Skeleton className='w-[150px] h-2 ' />
                    </div>
                    
                        <Skeleton className='w-[30px] h-[30px] rounded-full' />
                   

                </div>
            </div>
        </div>
    )
}

export default EpcContractorLoder