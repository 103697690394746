import dayjs from 'dayjs'
import { FaPhoneAlt } from "react-icons/fa"
import { Rating } from '@smastrom/react-rating'
import { useNavigate } from 'react-router-dom'
import { ICON_OCT_VERIFIED, IMG_EPC_COMPANY_PLACEHOLDER_LOGO } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { Button, Separator } from 'src/shadcn/components/ui'


export interface EpcItemData {
  manufacturer_name: string;
  review: number;
  location: string;
  companyLogo: string;
  products: any;
  product_efficiency: number;
  noOfYearsExperiance: number;
  _id?: string
}


type Props = {
  d: any
}

const ListItem = (d: any) => {

  const navigation = useNavigate()

  return (
    <div key={`${d?._id}}`} className='h-[400px] grid grid-rows-8 gap-1 rounded-lg border border-black overflow-hidden p-4'>
      <div className='row-span-4'>
        <img src={d?.productImage} className='rounded-xl' style={{ width: "100%", height: "100%" }} />
      </div>
      <div className='flex justify-center items-center h-100 w-100  text-center text-sm text-primary  font-semibold'>
        {d?.productName}
      </div>
      <div className='row-span-2 flex justify-center items-start h-100 w-100    text-xs text-center '>
        {d?.productDescription}
      </div>
      <div className=' flex items-center justify-between'>
        <div className='flex flex-col  space-y-2 '>
          <span className='text-sm text-center text-gray-400'>Quantity</span>
          <span className='text-sm text-center text-primary font-semibold'># 38</span>
        </div>
        <div className='flex flex-col  space-y-2'>
          <span className='text-sm text-center text-gray-400'>Price </span>
          <span className='text-sm text-center text-primary font-semibold'>₹ 102/piece</span>
        </div>
      </div>
    </div>
  )
}

export default ListItem