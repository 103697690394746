import { Separator } from '@radix-ui/react-separator'
import React from 'react'
import { Skeleton } from 'src/shadcn/components/ui/skeleton'

type Props = {}

const ListItemLoader = (props: Props) => {
    return (
        <div className='h-[400px] grid grid-rows-8 gap-1 rounded-lg border border-black overflow-hidden p-4'>
            <div className='row-span-4'>
                <Skeleton className='w-full h-full' />
            </div>
            <div className=' my-1 space-y-2 '>
                <Skeleton className='h-4 w-[150px]' />
            </div>
            <div className=' my-1 space-y-2 '>
                <Skeleton className='h-4 w-[150px]' />
            </div>
            <div className=' flex items-center justify-between'>
                <div className='flex flex-col  space-y-2 '>
                    <Skeleton className='h-8 w-[150px]' />
                    <Skeleton className='h-8 mt-2 w-[150px]' />
                </div>
                <div className='flex flex-col space-y-2'>
                    <Skeleton className='h-8 w-[150px]' />
                    <Skeleton className='h-8 mt-2 w-[150px]' />
                </div>
            </div>
        </div>
    )
}

export default ListItemLoader