import React from 'react'
import EPCContractorProfileTile from './EpcContractors/EPCContractorProfileTile'
import EpcContractorsList from './EpcContractors/EpcContractorsList'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES_CONST, MAIN_ROUTES_CONST } from 'src/navigations/modules'

type Props = {}

const EPCContractors = (props: Props) => {


    const navigate = useNavigate()

    return (
        <div className='bg-gray-50 p-12 flex justify-center items-start'>
            <div className='flex flex-col space-y-4 justify-center items-center  w-[80vw] '>
                <div className='font-semibold text-primary'>Our EPC Partners</div>
                <div className='text-3xl font-bold'>Top EPC Contractors</div>
                <EpcContractorsList />
                <div className='py-8'>
                    <RoundedButton  onClick={() => navigate("/" + MAIN_ROUTES_CONST.epcContractor)}>View More</RoundedButton>
                </div>
            </div>
        </div>
    )
}

export default EPCContractors