import { FC, Suspense } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import App from "src/App";
import { WithChildren } from "src/helpers";
import DefaultLayoutMain from "src/modules/Layouts/Default/DefaultLayoutMain";
import AboutUsMAin from "src/pages/Aboutus/AboutMain";
import ForgotMain from 'src/pages/Auth/Forgot/ForgotMain';
import VerifyOtpMain from 'src/pages/Auth/Otp/VerifyOtpMain';
import ResetpasswordMain from 'src/pages/Auth/Resetpassword/ResetpasswordMain';
import ContactusMain from 'src/pages/Contactus/ContactusMain';
import CrowdFundingMain from 'src/pages/CrowdFunding/CrowdFundingMain';
import DashboardMain from "src/pages/Dashboard";
import FaqMain from 'src/pages/Faq/FaqMain';
import GreenEnergyMain from 'src/pages/GreenEnergy/GreenEnergyMain';
import SuccessResetPassoword from 'src/pages/InfoDialogs/SuccessResetPassoword';
import MarketplaceMain from 'src/pages/Marketplace/MarketplaceMain';
import WelcomePage from 'src/pages/welcome';
import { APP_ROUTES_CONST, MAIN_ROUTES_CONST } from "./modules";
import CellMain from "src/pages/Cell";
import ProductDetails from "src/pages/Marketplace/ProductDetails";
import CellRoutes from "./CellRoutes";
import InvestmentMain from "src/pages/Investments/InvestmentMain";
import WalletMain from "src/pages/Wallet/WalletMain";
import ProfileMain from "src/pages/Profile";
import ExistingEnquiryForm from "src/pages/EnquiryForms/ExistingEnquiryForm";
import NewEnquiryForm from "src/pages/EnquiryForms/NewEnquiryForm";
import SellMain from "src/pages/Sell";
import { useAuth } from "src/modules/Auth/Core";
import P2pEnergyTradingMain from "src/pages/P2pEnergyTrading";
import EpcContractorMain from "src/pages/EpcContractors/EpcContractorMain";
import EmpContractorDetails from "src/modules/EpcContractors/EmpContractorDetails";
import ManufacturerDetails from "src/modules/Manufacturer/ManufacturerDetails";
import ManufacturerMain from "src/pages/Manufacturer/ManufacturerMain";
import SolarKIts from "src/pages/SolarKIts";

const { PUBLIC_URL } = process.env;
type Props = {};
const MainRoutes = (props: Props) => {


  return (
    <BrowserRouter>
      <Routes >
        <Route element={<App />}>
          <Route element={<DefaultLayoutMain />}>
            <Route index element={<WelcomePage />} />
            <Route path={MAIN_ROUTES_CONST.welcome} element={<WelcomePage />} />
            <Route path={MAIN_ROUTES_CONST.marketplace} element={<MarketplaceMain />} />
            <Route path={MAIN_ROUTES_CONST.forgot} element={<ForgotMain />} />
            <Route path={MAIN_ROUTES_CONST.verify_otp} element={<VerifyOtpMain />} />
            <Route path={MAIN_ROUTES_CONST.reset_password} element={<ResetpasswordMain />} />
            <Route path={MAIN_ROUTES_CONST.success_reset_password} element={<SuccessResetPassoword />} />
            <Route path={MAIN_ROUTES_CONST.faq} element={<FaqMain />} />
            <Route path={MAIN_ROUTES_CONST.about_us} element={<AboutUsMAin />} />
            <Route path={MAIN_ROUTES_CONST.contact_us} element={<ContactusMain />} />
            <Route path={MAIN_ROUTES_CONST.crowd_funding} element={<CrowdFundingMain />} />
            <Route path={MAIN_ROUTES_CONST.green_energy} element={<GreenEnergyMain />} />
            <Route path={MAIN_ROUTES_CONST.cell + "/*"} element={<CellRoutes />} />
            <Route path={MAIN_ROUTES_CONST.product_details + "/:id"} element={<ProductDetails />} />
            <Route path={MAIN_ROUTES_CONST.investment_calculator} element={<InvestmentMain />} />
            <Route path={APP_ROUTES_CONST.dashboard} element={<DashboardMain />} />
            <Route path={MAIN_ROUTES_CONST.wallet} element={<WalletMain />} />
            <Route path={MAIN_ROUTES_CONST.profile} element={<ProfileMain />} />
            <Route path={MAIN_ROUTES_CONST.existing_enquiry_rquest + "/:id"} element={<ExistingEnquiryForm />} />
            <Route path={MAIN_ROUTES_CONST.new_enquiry_request} element={<NewEnquiryForm />} />
            <Route path={MAIN_ROUTES_CONST.solarkit} element={<SolarKIts />} />
            <Route path={MAIN_ROUTES_CONST.p2pEnergyTrading} element={<P2pEnergyTradingMain />} />
            <Route path={MAIN_ROUTES_CONST.epcContractor} element={<EpcContractorMain />} />
            <Route path={MAIN_ROUTES_CONST.epcContractorDetails + "/:id"} element={<EmpContractorDetails />} />
            <Route path={MAIN_ROUTES_CONST.manufacturers} element={<ManufacturerMain />} />
            <Route path={MAIN_ROUTES_CONST.manufacturersDetails + "/:id"} element={<ManufacturerDetails />} />
            {/* {
              currentUser &&
              <Route path={APP_ROUTES_CONST.dashboard} element={<DashboardMain />} />
            } */}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  return <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
}

export default MainRoutes;
