import { ICON_BASKET, ICON_GREEN_ENERGY, ICON_PAYMENT_RECEIVE } from 'src/helpers'
import React from 'react'
import { ReactSVG } from 'react-svg'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { useNavigate } from 'react-router-dom'

type Props = {}

type IFetureSectionComponentProps = {
    icon: string,
    content: string,
    navigation: string | undefined
}

const FetureSectionComponent = ({ icon, content, navigation }: IFetureSectionComponentProps) => {

    const navigate = useNavigate()

    return <div className='flex space-x-4 p-4 items-center'>
        <div><ReactSVG src={icon} /></div>
        <div className='font-bold' >{content}</div>
    </div>
}

const FeaturesSection1 = (props: Props) => {

    const data: IFetureSectionComponentProps[] = [
        {
            icon: ICON_GREEN_ENERGY,
            content: "Buy clean Green energy",
            navigation: undefined
        },
        {
            icon: ICON_BASKET,
            content: "Purchase energy self-sufficiency products",
            navigation: "/"+MAIN_ROUTES_CONST.crowd_funding
        },
        {
            icon: ICON_PAYMENT_RECEIVE,
            content: "Receive payment for your energy surpluses",
            navigation: "/"+MAIN_ROUTES_CONST.green_energy
        }
    ]


    return (
        <div className='max-w-[80vw] mx-auto py-4 px-16 rounded-lg shadow-lg bg-white'>
            <div className='grid grid-cols-3 '>
                {
                    data.map((d: IFetureSectionComponentProps) => <FetureSectionComponent {...d} />)
                }
            </div>
        </div>
    )
}

export default FeaturesSection1