import React, { ChangeEvent, useState } from 'react'
import { Button, Checkbox, Input, Label, Separator } from 'src/shadcn/components/ui'
import { AUTHORIZED_PARTNER_OPTIONS, BUILDING_TYPES, convertToCamelCase, convertToNumber, optionTypes, PROJECT_TYPES, SPECIALIZATION_OPTIONS, SYSTEM_TYPES, VERIFIED_UNVERIFIED_OPTIONS } from 'src/helpers'
import Dropdown from 'src/shadcn/components/custom/Dropdown'
import SelectPicker2 from 'src/shadcn/components/custom/SelectPIcker2/SelectPicker2'

type Props = {
    onChange: (e: any) => void
}


const PROJECT_TYPE_FILTERS = ["Project rights", "Turnkey", "In operation", "transferable system", "Unused area"]
const SYSTEM_TYPE_FILTERS = ["Ground mounted", "Rooftop mounted"]

const FILTERS = [
    {
        name: 'Contractor Name',
        dataKey: "contractorName",
        type: 'text',
        // optionsType: "singleselect",
        // options: []
    },
    {
        name: 'City',
        dataKey: "city",
        type: 'text',
    },
    {
        dataKey: "Pincode",
        name: 'pincode',
        type: 'text',
    },
    {
        dataKey: "Specialization",
        name: 'specialization',
        type: 'dropdown',
        options: SPECIALIZATION_OPTIONS
        
    },
    {
        name: 'Authorized Partner',
        dataKey: "authorization_partner",
        type: 'list',
        optionsType: "multiselect",
        options: AUTHORIZED_PARTNER_OPTIONS
    },
    {
        name: 'Verified & Unverified',
        dataKey: "is_otc_verified",
        type: 'list',
        optionsType: "multiselect",
        options: VERIFIED_UNVERIFIED_OPTIONS
    },
];


const RESET_FILTER = {
    "authorization_partner": "",
    "is_otc_verified": "",
    "city": "",
    "contractorName": "",
    pincode: "",
    specialization: ""
}


const FilterMain = ({ onChange }: Props) => {

    const [selectedFilters, setselectedFilters] = useState<{
        "authorization_partner": string,
        "is_otc_verified": string,
        "city": string,
        "contractorName": string,
        pincode: string,
        specialization: string
    }>(RESET_FILTER)

    const handleFilterChange = (filterName: string, filterValue: any) => {

        let prepareState = { ...selectedFilters }
        prepareState = { ...prepareState, [filterName]: filterValue }

        setselectedFilters(prepareState);

    };

    console.log({ selectedFilters });


    return (
        <div>
            {
                FILTERS.map(({ name, dataKey, type, optionsType, options }: any) => {
                    if (optionsType == "multiselect" && type == "list") {
                        return <div key={`epc-filters-${name}-${type}`}>
                            <div className='my-2 text-sm font-bold text-primary uppercase'>{name}</div>
                            {
                                Array.isArray(options) && options.map((option: optionTypes, indx: any) => {
                                    const previusSelectedValues: Array<any> = selectedFilters[dataKey as keyof object] || []
                                    return <div key={`epc-filters-option-${name}-${indx}`} className=' flex justify-between mb-2'>
                                        <Label className=''>{convertToCamelCase(option.label)}</Label>
                                        {/* <Checkbox checked={previusSelectedValues?.includes(option)} onCheckedChange={(e) => {
                                            if (!e) {
                                                if (Array.isArray(previusSelectedValues) && previusSelectedValues.length > 0) {
                                                    const filterCurrentOption = previusSelectedValues.filter((d: any) => d != option)
                                                    handleFilterChange(dataKey, filterCurrentOption)
                                                }
                                            }
                                            else {
                                                if (Array.isArray(previusSelectedValues) && !previusSelectedValues.includes(option)) {
                                                    handleFilterChange(dataKey, [...previusSelectedValues, option])
                                                }
                                            }
                                        }} /> */}
                                        <Checkbox checked={selectedFilters[dataKey as keyof object] == option.value} onCheckedChange={(e) => {
                                            if (!e) {
                                                handleFilterChange(dataKey, "")
                                            }
                                            else {
                                                handleFilterChange(dataKey, option.value)
                                            }

                                        }} />
                                    </div>

                                })
                            }
                            <Separator />
                        </div>
                    }
                    // else if (type == "range") {
                    //     return <div key={`epc-filters-${name}-${type}`} >
                    //         <div className='my-2 font-bold text-primary uppercase'>{name}</div>
                    //         <div className='flex space-x-2'>
                    //             <Input className='flex-1' type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    //                 let values = e.target.value
                    //                 const { min, max } = selectedFilters.price;
                    //                 handleFilterChange(dataKey, [values, max])
                    //             }} placeholder='Min' />
                    //             <Input className='flex-1' type="number" placeholder='Max' onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    //                 let values = e.target.value
                    //                 const { min, max } = selectedFilters.price;
                    //                 handleFilterChange(dataKey, [min, values])
                    //             }} />
                    //         </div>
                    //     </div>
                    // }
                    else if (type == "text") {
                        return <div className='my-2' key={`epc-filters-${name}-${type}`}>
                            {/* <div>{name}</div> */}
                            <div className='flex flex-col'>
                                <Label className='my-2 text-sm font-bold text-primary uppercase'>{name}</Label>
                                <Input type="text" className='bg-white rounded-lg' placeholder='Enter' onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleFilterChange(dataKey, e.target.value)
                                }} />
                            </div>
                        </div>
                    }
                    else if (type == "dropdown") {
                        return <div className='my-2' key={`epc-filters-${name}-${type}`}>
                            {/* <div>{name}</div> */}
                            <div className='flex flex-col'>
                                <Label className='my-2 text-sm font-bold text-primary uppercase'>{name}</Label>
                                <SelectPicker2 options={options} searchable onchange={(e:any)=> handleFilterChange(dataKey, e)} />
                            </div>
                        </div>
                    }
                })
            }

            <div className='flex flex-col space-y-2'><Button className='rounded-full w-full my-2' onClick={() => {
                onChange(selectedFilters)
            }} >Apply</Button>
                <Button variant={"outline"} className='rounded-full w-full my-2' onClick={() => {
                    onChange(RESET_FILTER)
                    setselectedFilters(RESET_FILTER)
                }} >Clear Filters</Button></div>

        </div>
    )
}

export default FilterMain