import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Popover, PopoverContent, PopoverTrigger, Skeleton, Label } from '../../ui';
import RenderSelectedValues from './RenderSelectedValues';
import { CaretSortIcon } from '@radix-ui/react-icons';
import VirtualListFormMultiSelect from './VirtualListFormMultiSelect';
import { MainContextProvider, useMainContext } from './MainContext';
import { Search } from 'lucide-react';

import { cn, optionTypes } from 'src/helpers';
import { ClassValue } from 'clsx';


type Props = {
    options: optionTypes[],
    onchange?: (e: any) => void
    searchable?: boolean,
    disabled?: boolean,
    isLoading?: boolean,
    labelStyle?: string,
    className?: ClassValue
}

const Main = ({ options, onchange, searchable, disabled, isLoading, className }: Props) => {

    const { selectedValues } = useMainContext()
    const popupref = useRef<any>(null);
    const [width, setwidth] = useState(0)
    const [searchText, setsearchText] = useState<string>("")

    useEffect(() => {
        setwidth(popupref?.current?.clientWidth)
        return () => { }
    }, [popupref])

    let data = useMemo(() => {
        if (searchText !== "") {
            return options.filter((d: optionTypes) => d.value.toLowerCase().includes(searchText.toLowerCase()))
        }
        else return options
    }, [options, searchText])

    useEffect(() => {
        onchange && onchange(selectedValues && selectedValues.value)
        return () => { }
    }, [selectedValues])

    return (
        <div className='w-100 mt-2'>
            <Popover>
                <PopoverTrigger asChild>
                    <div
                        key={'multi-picker-trigger'}
                        ref={popupref}
                        role="combobox"
                        className={cn(
                            " bg-white flex w-full text-sm justify-between items-center px-2 font-normal min-h-[40px] border rounded", className, {
                            // "pointer-events-none bg-gray-100": disabled
                        })}
                    >

                        {isLoading ? <Skeleton className="w-full h-[15px]" /> : <div className="">
                            {selectedValues && selectedValues ? <RenderSelectedValues /> : `Select `}
                        </div>}

                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </div>
                </PopoverTrigger>
                {width && <PopoverContent className={cn("p-0 ")} style={{ width: width }} align="start">
                    <div className={cn(`relative p-1 z-50 overflow-hidden rounded-md border bg-popover text-popover-foreground  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2`, { width: width })}  >
                        <div className='flex flex-col'>
                            {searchable && <div className='flex items-center space-x-1 m-2'>
                                <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
                                <input
                                    type="text"
                                    className="focus:outline-none hover:outline-none py-2 text-sm w-full  "
                                    placeholder="Search"
                                    value={searchText}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setsearchText(e.target.value.toLowerCase())
                                    }
                                />
                            </div>}
                            <VirtualListFormMultiSelect
                                data={data}
                                onchange={(e: any) => {
                                    popupref.current?.click();
                                }}
                                selectedValues={selectedValues}
                            />
                        </div>
                    </div>
                </PopoverContent>}
            </Popover>
        </div>
    )
}

const SelectPicker2 = ({ labelStyle, ...rest }: Props) => {
    return <div>
        <MainContextProvider>
            <Main {...rest} />
        </MainContextProvider>
    </div>

}

export default SelectPicker2