import React, { ChangeEvent, useState } from 'react'
import ProjectTypeFilter from './ProjectTypeFilter'
import { Button, Checkbox, Input, Label, Separator } from 'src/shadcn/components/ui'
import { BUILDING_TYPES, convertToCamelCase, convertToNumber, NAME_REGEX, optionTypes, PROJECT_TYPES, SYSTEM_TYPES } from 'src/helpers'

type Props = {
    onChange: (e: any) => void
}


const PROJECT_TYPE_FILTERS = ["Project rights", "Turnkey", "In operation", "transferable system", "Unused area"]
const SYSTEM_TYPE_FILTERS = ["Ground mounted", "Rooftop mounted"]

const FILTERS = [
    {
        name: 'BUILDING TYPE',
        dataKey: "buildingType",
        type: 'list',
        optionsType: "multiselect",
        options: BUILDING_TYPES.map((d: optionTypes) => d.value)
    },
    {
        name: 'PROJECT TYPE',
        dataKey: "projectType",
        type: 'list',
        optionsType: "multiselect",
        options: PROJECT_TYPES.map((d: optionTypes) => d.value)
    },
    {
        name: 'SYSTEM TYPE',
        dataKey: "systemType",
        type: 'list',
        optionsType: "multiselect",
        options: SYSTEM_TYPES.map((d: optionTypes) => d.value)
    },
    // {
    //     name: 'PRICE',
    //     dataKey: "projectCost",
    //     type: 'range',
    //     min: 1000,
    //     max: 1000000000,
    // },
    {
        name: 'City',
        dataKey: "city",
        type: 'text',
        regex: NAME_REGEX
        // optionsType: "singleselect",
        // options: []
    },
    {
        name: 'Country',
        dataKey: "country",
        type: 'dropdown',
        optionsType: "singleselect",
        options: []
    },
    {
        name: 'Project ID',
        dataKey: "_id",
        type: 'text',
    },
    {
        dataKey: "projectName",
        name: 'Project Name',
        type: 'text',
        regex: NAME_REGEX
    },
];


const RESET_FILTER = {
    "projectType": [],
    "systemType": [],
    // "price": {
    //     min: 0,
    //     max: 0
    // },
    "city": "",
    "country": "",
    project_id: "",
    projectName: ""
}


const FilterMain = ({ onChange }: Props) => {

    const [selectedFilters, setselectedFilters] = useState<{
        "projectType": string[],
        "systemType": string[],
        // "price": {
        //     min: number,
        //     max: number
        // },
        "city": string,
        "country": string,
        project_id: string,
        projectName: string
    }>(RESET_FILTER)

    const handleFilterChange = (filterName: string, filterValue: any) => {

        let prepareState = { ...selectedFilters }
        prepareState = { ...prepareState, [filterName]: filterValue }

        setselectedFilters(prepareState);

    };

    console.log({ selectedFilters });


    return (
        <div>
            {
                FILTERS.map(({ name, dataKey, type, optionsType, options, regex }: any) => {
                    if (optionsType == "multiselect" && type == "list") {
                        return <div key={`marketplacefilter-${name}-${type}`}>
                            <div className='my-2 font-bold text-primary uppercase'>{name}</div>
                            {
                                Array.isArray(options) && options.map((option: any, indx: any) => {
                                    const previusSelectedValues: Array<any> = selectedFilters[dataKey as keyof object] || []
                                    return <div key={`marketplacefilter-option-${name}-${indx}`} className=' flex justify-between mb-2'>
                                        <Label>{convertToCamelCase(option)}</Label>
                                        <Checkbox checked={previusSelectedValues?.includes(option)} onCheckedChange={(e) => {
                                            if (!e) {
                                                if (Array.isArray(previusSelectedValues) && previusSelectedValues.length > 0) {
                                                    const filterCurrentOption = previusSelectedValues.filter((d: any) => d != option)
                                                    handleFilterChange(dataKey, filterCurrentOption)
                                                }
                                            }
                                            else {
                                                if (Array.isArray(previusSelectedValues) && !previusSelectedValues.includes(option)) {
                                                    handleFilterChange(dataKey, [...previusSelectedValues, option])
                                                }
                                            }
                                        }} />
                                    </div>

                                })
                            }
                            <Separator />
                        </div>
                    }
                    // else if (type == "range") {
                    //     return <div key={`marketplacefilter-${name}-${type}`} >
                    //         <div className='my-2 font-bold text-primary uppercase'>{name}</div>
                    //         <div className='flex space-x-2'>
                    //             <Input className='flex-1' type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    //                 let values = e.target.value
                    //                 const { min, max } = selectedFilters.price;
                    //                 handleFilterChange(dataKey, [values, max])
                    //             }} placeholder='Min' />
                    //             <Input className='flex-1' type="number" placeholder='Max' onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    //                 let values = e.target.value
                    //                 const { min, max } = selectedFilters.price;
                    //                 handleFilterChange(dataKey, [min, values])
                    //             }} />
                    //         </div>
                    //     </div>
                    // }
                    else if (type == "text") {
                        return <div className='my-2' key={`marketplacefilter-${name}-${type}`}>
                            {/* <div>{name}</div> */}
                            <div className='flex flex-col'>
                                <Label className='mb-2'>{name}</Label>
                                <Input type="text" value={selectedFilters[dataKey as keyof object] || ""} placeholder={name} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    if (e.target.value) {
                                        if (regex) {
                                            handleFilterChange(dataKey, e.target.value.replace(regex, ""))
                                        }
                                        else handleFilterChange(dataKey, e.target.value)
                                    }
                                    else handleFilterChange(dataKey, "")

                                }} />
                            </div>
                        </div>
                    }
                })
            }

            <div className='flex flex-col space-y-2'><Button className='rounded-full w-full my-2' onClick={() => {
                onChange(selectedFilters)
            }} >Apply</Button>
                <Button variant={"outline"} className='rounded-full w-full my-2' onClick={() => {
                    onChange(RESET_FILTER)
                    setselectedFilters(RESET_FILTER)
                }} >Clear Filters</Button></div>

        </div>
    )
}

export default FilterMain