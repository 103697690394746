import React from 'react'
import ListItem from './ListItem'

type Props = {
  data: any
}



const ListItems = ({ data }: Props) => {
  return (
    <div className='grid grid-cols-3 gap-2'>
      {data && Array.isArray(data) && data.length > 0 ? data.map((d: any) => {
        return <ListItem {...d} />
      }) : <div className='flex h-[400px] w-100 justify-center items-center' >
        No Content
      </div>}
    </div>

  )
}

export default ListItems