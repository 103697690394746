import React, { useMemo } from 'react'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import EPCContractorProfileTile from './EPCContractorProfileTile'
import EpcContractorLoder from './EpcContractorLoder'

type Props = {}

const Main = () => {

    const aopiResp = useQueryResponseData()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => {
        if (aopiResp && Array.isArray(aopiResp) && aopiResp.length > 0) {
            return aopiResp.slice(0, 4)
        }
    }, [aopiResp])

    return <div className=' grid grid-cols-4 gap-4 w-full'>
        {
            !isLoading ? <>
                {
                    data && data.length > 0 ? data.map((d: any) => <EPCContractorProfileTile {...d} />) : null
                }
            </> : <>
                {
                    [...new Array(4)].map((d: any) => <EpcContractorLoder />)
                }
            </>
        }

    </div>
}




const EpcContractorsList = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default EpcContractorsList